<template>
    <div id='jobs'>
        <CRow>
        <CCol md="2">
        </CCol>
        <CCol md="8">

        <ul v-if="fetching">
            <div class="center-align">
                <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>fetching jobs</p>
            </div>
        </ul>
        <div>
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <a-icon type="solution" />
                        <strong> Jobs </strong>
                    </CCardHeader>
                    <CCardBody>
                        <CListGroup >
                            <div v-for="(job) in jobs" :key="job.jobId">
                                <JobRow :jobId="job.jobId" :jobStatus="job.jobStatus" :mail="job.mail" :email="job.email" :customerName="job.customerName" :createdDate="job.createdDate" />
                            </div>
                        </CListGroup>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
        </CCol>
                </CRow>
    </div>
</template>

<script>
import axios from 'axios'
import JobRow from '@/components/JobRow.vue'

export default {
    name: 'Jobs',
    components: {
        JobRow
    },
    data() {
        return {
            jobs: null,
            fetching: true
        }
    },
    // icons: { cisMobile, cibMailRu, cilPencil },
    methods: {
        fetchJobs () {
            console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
            const url = process.env.VUE_APP_CRM_API_ROOT + '/jobs';
            console.log('fetch jobs')
            this.fetching = true;

            this.$cognitoAuth.getIdToken((err, result) => {
                if (err) { 
                    this.error = err;
                    delete this.$http.defaults.headers.common['Authorization'];
                    this.fetching = false;
                } else {
                    // const url = config.s3SignedUrl;
                    console.log('Jobs, axios token: ' + result)
                    this.$http.defaults.headers.common['Authorization'] = result;
                    axios({ method: 'get', url: url})
                        .then(response => {
                            console.log('fetch jobs, response: ' + response)
                            this.jobs = response.data
                            this.fetching = false;
                            if (this.jobs) {
                                console.log('fetch jobs, split new jobs');
                            }
                        })
                        .then(status => {
                            console.log('fetch jobs, axios status: ' + status)
                            this.status = status;
                        })
                        .catch(err => {
                            this.fetching = false;
                            console.log('fetch jobs, axios err: ' + err)
                            console.log('err properties...')
                            Object.keys(err).forEach((prop)=> console.log(prop));
                            console.log('err.response properties...')
                            console.log('fetch jobs, axios err.isAxiosError: ' + err.isAxiosError)
                            console.log('fetch jobs, axios err.toJSON: ' + err.toJSON)
                            console.log('fetch jobs, axios err.request: ' + err.request)
                            this.error = err;
                            console.log('err.request properties...')
                            Object.keys(err.request).forEach((prop)=> console.log(prop));
                        })
                }
            })
        }
    },
    rowClicked (item, index) {
        console.log('clicked row...')
      this.$router.push({path: `job/${index + 1}`})
    },

    mounted () {
        console.log('Jobs:mounted')
        this.fetchJobs()

    },
    created () {
        console.log('Jobs:created')
    },
    updated () {
        console.log('Jobs:updated')
    }
}

</script>


<style scoped>

</style>
