<template>
    <div id='jobRow'>
        <CListGroupItem
            :to="{ name: 'job', params: { jobId: jobId }}"
            class="flex-column align-items-start list-item"
        >
            <CRow>
                <CCol class="text-truncate">
                    <div class="d-flex w-100 justify-content-between">
                    <span>
                        <span class="indicator"><CBadge color="primary">{{jobStatus}}</CBadge></span>
                        <span class="indicator" v-if="email && email != ''"><a-icon type="mail" /></span>
                        <span class="indicator" v-if="phone && phone != ''"><a-icon type="phone" /></span>
                    </span>
                    <small>{{ createdDate | formatDateAgo }}</small>
                    </div>

                    <span><strong>{{ customerName }}</strong></span>
                </CCol>
            </CRow>
        </CListGroupItem>
    </div>
</template>

<script>

export default {
    name: 'JobRow',
    components: {
        
    },
    props: {
        jobId: Number,
        jobStatus: String,
        customerName: String,
        createdDate: String,
        email: String,
        phone: String
    }
}

</script>

<style>
.indicator {
    margin-right: 10px;
}

.list-item {
    padding: 10px;
}
</style>

